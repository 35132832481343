import React from 'react'
import Banner from '../home/Banner'
import Services from '../home/Services'
import AboutHome from '../home/AboutHome'
import WhyChooseUs from '../home/WhyChooseUs'
import OurClients from '../home/OurClients'
export default function Home() {
    return (
        <React.Fragment>
            <Banner />
            <Services />
            <AboutHome />
            <WhyChooseUs />
            <OurClients />
        </React.Fragment>
    )
}
