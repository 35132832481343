import React  from 'react'
import { Container, Nav,  Navbar, Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'

export default function Header() {    
    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary menuHead">
            <Container>
                <Navbar.Brand href="/"><img src={logo} alt=''/></Navbar.Brand>
                <Navbar.Toggle aria-controls={`MainMenu`} />
                <Navbar.Offcanvas
                    id={`MainMenu`}
                    aria-labelledby={`MainMenuHeader`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`MainMenuHeader`}>
                        <img src={logo} alt=''/>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav.Link as={Link} eventKey="1" to="/">Home</Nav.Link>
                            <Nav.Link as={Link} eventKey="2" to="/about-us">About us</Nav.Link>
                            <Nav.Link as={Link} eventKey="3" to="/our-services">Our Services</Nav.Link>
                            <Nav.Link as={Link} eventKey="4" to="/our-work">Our Work</Nav.Link>
                            <Nav.Link as={Link} eventKey="5" to="/career">Career</Nav.Link>
                            <Nav.Link as={Link} eventKey="6" to="/contact-us">Contact us</Nav.Link>
                            {/* <NavDropdown
                                title="Dropdown"
                                id={`offcanvasNavbarDropdown-expand1`}
                            >
                                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action4">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action5">
                                    Something else here
                                </NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}
