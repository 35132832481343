import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import serImg from '../assets/images/service.png'
import serImg2 from '../assets/images/service2.png'
import serImg3 from '../assets/images/service3.png'
import serImg4 from '../assets/images/service4.png'
import { Link } from 'react-router-dom'
export default function Services() {
  return (
    <div className='serviceSection mt-0 pt-0'>
        <div className='pageBanner mb-5'>
        <Container>
          <h1>Our Services</h1>
        </Container>
      </div>
            <Container>
                
                <Row className='g-3 mt-3'>
                    <Col md={6}>
                        <div className='servInn h-100'>
                            <Row className='align-items-center'>
                                <Col md="6" className='order-md-2'><img src={serImg} alt='' className='img-fluid' /></Col>
                                <Col md="6">
                                    <h4>Website Designing</h4>
                                    <p>Well-websites designing and prepared web sites are an essential part of every organization's communication needs.</p>
                                    <Link to="#" className='readMore'>Read More</Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='servInn h-100'>
                            <Row className='align-items-center'>
                                <Col md="6" className='order-md-2'><img src={serImg2} alt='' className='img-fluid' /></Col>
                                <Col md="6">
                                    <h4>Web Development</h4>
                                    <p>Began with Web Application Development with experience and absolutely incomparable quality.</p>
                                    <Link to="#" className='readMore'>Read More</Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='servInn h-100'>
                            <Row className='align-items-center'>
                                <Col md="6" className='order-md-2'><img src={serImg3} alt='' className='img-fluid' /></Col>
                                <Col md="6">
                                    <h4>Multimedia And Graphic-design</h4>
                                    <p>Offers a wide variety of specialized graphic design services including logo design,</p>
                                    <Link to="#" className='readMore'>Read More</Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='servInn h-100'>
                            <Row className='align-items-center'>
                                <Col md="6" className='order-md-2'><img src={serImg4} alt='' className='img-fluid' /></Col>
                                <Col md="6">
                                    <h4>Web Hosting</h4>
                                    <p>A service provider that offers shared hosting or committed hosting of a service or services to customers.</p>
                                    <Link to="#" className='readMore'>Read More</Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='servInn h-100'>
                            <Row className='align-items-center'>
                                <Col md="6" className='order-md-2'><img src={serImg2} alt='' className='img-fluid' /></Col>
                                <Col md="6">
                                    <h4>Web Development</h4>
                                    <p>Began with Web Application Development with experience and absolutely incomparable quality.</p>
                                    <Link to="#" className='readMore'>Read More</Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='servInn h-100'>
                            <Row className='align-items-center'>
                                <Col md="6" className='order-md-2'><img src={serImg3} alt='' className='img-fluid' /></Col>
                                <Col md="6">
                                    <h4>Multimedia And Graphic-design</h4>
                                    <p>Offers a wide variety of specialized graphic design services including logo design,</p>
                                    <Link to="#" className='readMore'>Read More</Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='servInn h-100'>
                            <Row className='align-items-center'>
                                <Col md="6" className='order-md-2'><img src={serImg4} alt='' className='img-fluid' /></Col>
                                <Col md="6">
                                    <h4>Web Hosting</h4>
                                    <p>A service provider that offers shared hosting or committed hosting of a service or services to customers.</p>
                                    <Link to="#" className='readMore'>Read More</Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='servInn h-100'>
                            <Row className='align-items-center'>
                                <Col md="6" className='order-md-2'><img src={serImg3} alt='' className='img-fluid' /></Col>
                                <Col md="6">
                                    <h4>Multimedia And Graphic-design</h4>
                                    <p>Offers a wide variety of specialized graphic design services including logo design,</p>
                                    <Link to="#" className='readMore'>Read More</Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
  )
}
