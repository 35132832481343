// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './assets/css/bootstrap-icons.min.css';
import './assets/css/all.min.css';
import './App.css';
import './assets/css/style.scss';
import './assets/css/custom.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Header from './common/Header';
import Footer from './common/Footer';
import React from 'react';
import About from './pages/About';
import PageNotFound from './common/PageNotFound';
import Services from './pages/Services';
import ContactUs from './pages/Contact';

function App() {
  return (
    <React.Fragment>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us/*" element={<About />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route path="/our-services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
