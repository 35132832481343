import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function ContactUs() {
    return (
        <div className='pageStyle'>
            <div className='pageBanner mb-5'>
                <Container>
                    <h1>Contact Us</h1>
                </Container>
            </div>
            <div className='contactForm'>
                <Container>
                    <Row>
                        <Col md='7'>
                            <div className='mb-4 titielStyle'>
                                <span className='smallTitle'>CONTACT NOW</span>
                                <h2>Have Question?<br /> Write a Message</h2>
                                <p>We will catch you as early as we receive the message</p>
                            </div>
                            <Form>
                                <Row>
                                    <Col md="6">
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Enter name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter name" />
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" />
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Enter mobile</Form.Label>
                                            <Form.Control type="text" placeholder="Enter mobile" />
                                        </Form.Group>
                                    </Col>
                                    <Col md="6">
                                        <Form.Group className="mb-3" controlId="">
                                            <Form.Label>Enter mobile</Form.Label>
                                            <Form.Control type="text" placeholder="Enter mobile" />
                                        </Form.Group>
                                    </Col>
                                    <Col md="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Enter your message</Form.Label>
                                            <Form.Control as="textarea" placeholder='Enter your message' rows={3} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button className='btn btnstyle px-5' type="submit">
                                    Submit
                                    <span className="circle"></span>
                                </Button>
                            </Form>
                        </Col>
                        <Col md='5'>
                            <div className='contactRight mb-4'>
                                <h5><i className="bi bi-headset"></i> Phone:</h5>
                                <p>Assistance hours: Monday – Friday, 9 am to 5 pm</p>
                                <Link to="#" className='readMore'>(+91) 0000 000 000</Link>
                            </div>
                            <div className='contactRight mb-4'>
                                <h5><i className="bi bi-envelope-paper"></i> Email:</h5>
                                <p>Our support team will get back to in 24-h during standard business hours.</p>
                                <Link to="#" className='readMore'>info@businessname.com</Link>
                            </div>
                            <div className='contactRight mb-4'>
                                <h5><i className="bi bi-skype"></i> Skype:</h5>
                                <p>We Are Online: Monday – Friday, 9 am to 5 pm</p>
                                <Link to="" className='readMore'>company.company</Link>
                            </div>
                            <div className='contactRight mb-4'>
                                <h5><i className="bi bi-diagram-3"></i> Address:</h5>
                                <Link to="">123 Business Centre London SW1A 1AA</Link>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </div>
    )
}
