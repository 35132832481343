import React from 'react'
import BannerImg from '../assets/images/eCommerce.gif'
import { Carousel } from 'react-bootstrap'

export default function Banner() {
    return (
        <Carousel indicators={false}   interval={5000} hover={true}>
            <Carousel.Item>
                <img src={BannerImg} className="d-block w-100" alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={BannerImg} className="d-block w-100" alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={BannerImg} className="d-block w-100" alt="First slide" />
            </Carousel.Item>
        </Carousel>
    )
}
