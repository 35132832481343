import React from 'react'
import { Container } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import logo1 from '../assets/images/logo/1.png';
import logo2 from '../assets/images/logo/2.png';
import logo3 from '../assets/images/logo/3.png';
import logo4 from '../assets/images/logo/4.png';
import logo5 from '../assets/images/logo/5.png';
import logo6 from '../assets/images/logo/6.png';
export default function OurClients() {
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 6,

            }
        },
    };
    return (
        <div className='py-4 OurClients'>
            <Container>
                <div className='text-center titielStyle mb-5'>
                    <span  className='smallTitle'>Our Clients</span>
                    <h2>Our Clients</h2>
                </div>
                <OwlCarousel className='owl-theme' {...options}>
                    <div class='item'>
                        <img src={logo1} className='logoClients' alt='' />
                    </div>
                    <div class='item'>
                    <img src={logo2} className='logoClients' alt='' />
                    </div>
                    <div class='item'>
                    <img src={logo3} className='logoClients' alt='' />
                    </div>
                    <div class='item'>
                    <img src={logo4} className='logoClients' alt='' />
                    </div>
                    <div class='item'>
                    <img src={logo5} className='logoClients' alt='' />
                    </div>
                    <div class='item'>
                    <img src={logo6} className='logoClients' alt='' />
                    </div>
                </OwlCarousel>;
            </Container>
        </div>
    )
}
