import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img1 from '../assets/images/computers.svg';
import img2 from '../assets/images/worker.svg';
import img3 from '../assets/images/deal.svg';
import img4 from '../assets/images/marketingService.png';
import { Link } from 'react-router-dom';
export default function WhyChooseUs() {
    return (
        <div className='WhyChooseUsBx'>
            <Container>
                <Row className='justify-content-md-center'>
                    <Col md={9}>
                        <div className='text-center titielStyle mb-5'>
                            <span className='smallTitle'>WHY CHOOSE US</span>
                            <h2>Performance Marketing Services We Offer</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                    </Col>
                </Row>

                <Row className='py-5'>
                <Col md={6} sm={{ order: 2 }}><img src={img4} alt='' className='img-fluid' /></Col>
                    <Col md={6}>
                        <Row className='mb-4'>
                            <Col md={2} xs={2}><img src={img1} alt='' className='img-fluid' /></Col>
                            <Col md={10} xs={10}>
                                <h5>High-Quality Leads</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                            </Col>
                        </Row>
                        <Row className='mb-4'>
                            <Col md={2}xs={2}><img src={img2} alt='' className='img-fluid' /></Col>
                            <Col md={10}xs={10}>
                                <h5>Real Time Leads</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                            </Col>
                        </Row>
                        <Row className='mb-4'>
                            <Col md={2}xs={2}><img src={img3} alt='' className='img-fluid' /></Col>
                            <Col md={10}xs={10}>
                                <h5>Get Consistent Leads</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper. Mauris aliquet egestas metus.</p>
                            </Col>
                        </Row>
                    </Col>
                   
                </Row>
            </Container>
            <div className="text-center bg-black py-5 text-white">
                <Container>
                    <h2>Get your <strong className='text-danger'>FREE Digital</strong> Audit</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc aliquet ligula nec leo elementum semper.</p>
                    <Link to="#" className='btn btnstyle '>Get Strategy Call <span className='circle'></span></Link>
                </Container>
            </div>
        </div>
    )
}
