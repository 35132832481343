import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import aboutimg from '../assets/images/about.png';
import { Link } from 'react-router-dom';
export default function AboutHome() {
  return (
    <div className='AboutHome'>
      <Container>
        <Row className='align-items-center'>
            <Col md={5} className='order-md-2'><img src={aboutimg} className='img-fluid' alt=''/></Col>
            <Col md={7}>
                <div className='mb-4 titielStyle'>
                    <span className='smallTitle'>WE ARE CREATIVE AGENCY</span>
                    <h2>Welcome to Company Name</h2>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. is simply dummy text of the printing and typesetting industry.</p>
                {/* <Link to="#" className='readMore'>Read More</Link> */}
                <Link to="#" className='btn btnstyle mt-4'>Read More <span className='circle'></span></Link>
            </Col>
        </Row>
      </Container>
    </div>
  )
}
