import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
export default function Footer() {
  return (
    <div className='mt-5 FooterBx'>
      <Container>
        <Row>
          <Col md="2">
            <h4>Navigation </h4>
            <ul>
              <li><Link to="/" className='readMore'>Home</Link></li>
              <li><Link to="/" className='readMore'>About us</Link></li>
              <li><Link to="/" className='readMore'>Portfolio</Link></li>
              <li><Link to="/" className='readMore'>Career</Link></li>
              <li><Link to="/" className='readMore'>Terms and Conditions</Link></li>
              <li><Link to="/" className='readMore'>Privacy Policy</Link></li>
              <li><Link to="/" className='readMore'>Contact Us</Link></li>
            </ul>
          </Col>
          <Col md="3">
            <h4>Services</h4>
            <ul>
              <li><Link to="/" className='readMore'>Website Design & Development</Link></li>
              <li><Link to="/" className='readMore'>E-commerce Website Design</Link></li>
              <li><Link to="/" className='readMore'>Software Development</Link></li>
              <li><Link to="/" className='readMore'>App Development</Link></li>
              <li><Link to="/" className='readMore'>SEO/SMO Services</Link></li>
              <li><Link to="/" className='readMore'>Email Marketing</Link></li>
              <li><Link to="/" className='readMore'>Hosting & Domains</Link></li>
            </ul>
          </Col>
          <Col md="4">
            <h4>Contact Us</h4>
            <ul>
              <li><strong className='text-white'><i className="bi bi-envelope-paper"></i> Email</strong><br /><Link to="/" className='readMore'>info@companyname.com</Link></li>
              <li><strong className='text-white'><i className="bi bi-telephone-inbound"></i> Phone</strong><br /><Link to="/" className='readMore'>+91 9876543210</Link></li>
              <li><strong className='text-white'><i className="bi bi-geo-alt"></i>  Address</strong><br /> 123 Business Centre London SW1A 1AA</li>
            </ul>
          </Col>
          <Col md="3">
            <h4>Follow Us</h4>
            <div className='d-flex gap-2'>
            <Link to="#"><i className="bi bi-geo-alt"></i></Link>
            <Link to='#'><i className="bi bi-twitter"></i></Link>
            <Link to='#'><i className="bi bi-linkedin"></i></Link>
            </div>
          </Col>
        </Row>
      </Container>
      <div className='border-top py-2 mt-5'>
        <Container>
          <Row>
            <Col>Copyright Company Name @ 2023. All right reserved</Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
